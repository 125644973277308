import React from "react";
import { useSelector } from "react-redux";
import { comission } from "../common/sections";
import Prep from "../common/Prep";
import Tech from "../common/Tech";
import Sup from "../common/Sup";
import Sci from "../common/Sci";

const Commitee = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section id="commitee" className="speakers-section-two">
      <div className="anim-icons">
        <span className="icon icon-circle-4 wow zoomIn"></span>
        <span className="icon icon-circle-3 wow zoomIn"></span>
      </div>

      <div className="auto-container">
        <div className="sec-title text-center">
          <h2 className="title" style={{ fontSize: "3rem" }}>
            {" "}
            {comission[lang]}
          </h2>
        </div>

        <div className="row">
          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د. علي الزناتي </a>
                </h4>
                <span className="designation">معالي وزير الصحة</span>
                <span className="designation">الرئيس الفخري للمؤتمر</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/zenati.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د.مفتاح محمد المرابط</a>
                </h4>
                <span className="designation">السيد رئيس الجامعة</span>
                <span className="designation">رئيس المؤتمر</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/muftah.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د.انتصار محمد بزاقة </a>
                </h4>
                <span className="designation">عميد كلية الصحة العامة العجيلات</span>
                <span className="designation">نائب رئيس المؤتمر</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/intesar.jpeg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د.حمزة الهادي كشلاف</a>
                </h4>
                <span className="designation">مسجل عام الجامعة </span>
                <span className="designation">رئيس اللجنة التحضيرية </span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/hamza123.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          
        </div>
        <div className="row">
          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د.حسن موتات  </a>
                </h4>
                <span className="designation">نائب رئيس اللجنة التحضرية</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/motat.jpg" alt="" />
                  </a>
                </figure>
              </div>
              <div className="social-box">
              <ul className="text-white">
                  <li>
                    <Prep lang={lang} />
                    </li>
                </ul>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د.نوري  العربي الأمين كشلاف </a>
                </h4>
                <span className="designation">رئيس اللجنة العلمية </span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/nori.jpeg" alt="" />
                  </a>
                </figure>
              </div>
              <div className="social-box">
              <ul className="text-white">
                  <li>
                    <Sci lang={lang} />
                    </li>
                </ul>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د.حسن مولود الشيباني</a>
                </h4>
                <span className="designation">رئيس اللجنة التقنية</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/hassan.jpeg" alt="" />
                  </a>
                </figure>
              </div>
              <div className="social-box">
              <ul className="text-white">
                  <li>
                    <Tech lang={lang} />
                    </li>
                </ul>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">علي عبد السلام كريم </a>
                </h4>
                <span className="designation">رئيس لجنة الخدمات</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/man.png" alt="" />
                  </a>
                  
                </figure>
              </div>
              <div className="social-box">
              <ul className="text-white">
                  <li>
                    <Sup lang={lang} />
                    </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">عبد الناصر على المرابط</a>
                </h4>
                <span className="designation">رئيس لجنة الاستقبال والخدمات </span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-3.jpg" alt="" />
                  </a>
                </figure>
              </div>
              <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Commitee;
