export const navlink1 = {
  ar: "الرئيسية ",
  en: "Home",
  fr: "Page d'accueil",
};
export const navlink2 = {
  ar: "الجهة المنظمة ",
  en: "Organizers",
  fr: "Les organisateurs",
};
export const navlink2_1 = {
  ar: "الجهات الراعية ",
  en: "Organizers",
  fr: "Les organisateurs",
};
export const navlink3 = {
  ar: "المتحدثون ",
  en: "Speakers",
  fr: "Haut-parleurs",
};

export const navlink4 = {
  ar: "عن المؤتمر ",
  en: "About",
  fr: "À propos",
};
export const navlink4_4 = {
  ar: "كيفية التسجيل ",
  en: "How To Regester",
  fr: "Comment s'inscrire",
};
export const navlink4_5 = {
  ar: "العدادات",
  en: "Conference statistics",
  fr: "Statistiques de la conférence",
};
export const navlink5 = {
  ar: "رعاة المؤتمر ",
  en: "Sponsors",
  fr: "Les sponsors",
};
export const navlink6 = {
  ar: "اللجان المنظمة ",
  en: "Supervisory committees",
  fr: "Comités de surveillance",
};
export const navlink7 = {
  ar: "مواعيد مهمة",
  en: "Important Dates",
  fr: "Rendez-vous importants",
};

export const head1 = {
  ar: "المؤتمر العلمي الدولي الأول  لكلية الصحة العامة العجيلات ",
  en: "The 1st International Conference of the Faculties of Sciences",
  fr: " ",
};

export const head2 = {
  ar: "تحت شعار",
  en: "The role of the private sector in achieving the goals of sustainable development",
  fr: "Le rôle du secteur privé dans la réalisation des objectifs du développement durable",
};
export const head2_1 = {
  ar: "العلاج بالداخل مطلب وطني",
  en: "The role of the private sector in achieving the goals of sustainable development",
  fr: "Le rôle du secteur privé dans la réalisation des objectifs du développement durable",
};

export const head3 = {
  ar: "(الواقع، التحديات، الانعكاسات)",
  en: "Tunisia",
  fr: "Tunisia",
};

export const head4 = {
  ar: "مقدمة",
  en: "Foreword",
  fr: "AVANT-PROPOS ",
};

export const Timing = {
  ar: "الوقت ",
  en: "Timing",
  fr: "Timing",
};
export const Place = {
  ar: "المكان ",
  en: "Place",
  fr: "Endroit",
};
export const PublicMadels = {
  ar: "لتعرف و لتحميل قالب النشر",
  en: "To download the publication template",
  fr: "Pour télécharger le modèle de publication",
};
export const folded = {
  ar: "مطوية المؤتمر",
  en: "Conference folded",
  fr: "Conférence pliée",
};

export const head5 = {
  ar: "اهلا بكم في المؤتمر الدولي العلمي الاول  بعنوان توطين العلاج بالداخل ",
  en: "Welcome To The 1st International Conference of the Faculties of Sciences",
  fr: " ",
};
export const Notes = {
  ar: `
   `,
  en: `
  
  `,
  fr: `
  
  `,
};

export const about1 = {
  ar: `شهدت السنوات الأخيرة نموًا كبيرًا ومتزايدًا في قطاع الخدمات بشكل عام والذي أصبح يشغل مكانة متميزة في اقتصاديات الدول، ويعتبر قطاع الخدمات الصحية من أهم القطاعات الخدمية التي تحظى باهتمام خاص؛ بسبب الأهمية التي تفرضها طبيعة الخدمات الصحية، وعلاقتها المباشرة بأفراد المجتمع.								 فقد أصبحت جودة الخدمات الصحية وتنوعها أحد أهم المعايير التي تعكس مدى التطور الاقتصادي والاجتماعي والثقافي للدول وما تدعو إليه (UNDP) 2030.
  وانطلاقا من دور جامعة الزاوية في خدمة المجتمع والبيئة المحيطة والمساهمة في تقديم الاستشارات واقتراح الحلول أمام كل العقبات التي تواجه المجتمع الليبي، فكان لزامًا البحث عن الأسباب والمعوقات التي تقف أمام توطين العلاج بالداخل، الأمر الذي دفع كلية الصحة العامة – العجيلات إلى عقد هذا المؤتمر لإيجاد الحلول والمقترحات أمام التحديات والصعوبات التي تواجه قطاع الخدمات الطبية في ليبيا، والاستفادة من كل التجارب والخبرات المحلية والدولية في هذا الشأن.
  `,
  en: `The Faculty of Sciences at University of Zawia is pleased to organize the first international conference under the slogan of basic science the basis of creativity and brilliance. Organizing this conference is motivated by the importance of research in the various departments of the faculties of sciences. This conference aspires to contribute to the enrichment of scientific research within academic institutions.The Faculty of Sciences in Zawia is one of the largest and oldest faculties of University of Zawia. It was established in 1988, and it now includes the following scientific departments:  Computer - Zoology - Chemistry - Botany - Physics - Geology - Statistics - Mathematics. The faculty of Sciences of Al-Ajilat was established in Al-Fraiche in 2000 with parallel departments to the faculty of Sciences in Zawia `,

  fr: `  `,
};

export const about2 = {
  ar: `للتعرف و لتحميل قالب النشر  :`,
  en: "Journal publishing rules and conditions",
  fr: `Règles et conditions de publication de revues`,
};
export const ContactUsTitle = {
  ar: `للتواصل`,
  en: "To communicate ",
  fr: `Communiquer`,
};

export const contactUs = {
  ar: `
<h4>
  <span class="icon far fa-calendar"></span> 23-22/فبراير/2022 
</h4>
<div class="text"> المراسلات والتواصل : </div>
<div class="text"> المنسقون </div>
<ul class="info-list">
<li>
    <span class="icon icon_profile"></span>أ. دليلة الطاهر الختروشي-جامعة الزاوية 
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00218926086576">00218926086576</a>
  </li>
  <li>
  <span class="icon icon_profile"></span>أ. نهلة فائز عبدالله-المعهد العالي للعلوم والتقنية طرابلس 
</li>
<li>
  <span class="icon icon_phone"></span>
  <a href="tel:00218914564820">00218914564820</a>
</li>
  <li>
    <span class="icon icon_profile"></span>أ. اسماء حسن العقيلي-جامعة بنغازي 
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:002189165379950">00218916537995</a>
  </li>
  
  <li>
 بريد المؤتمر
</li>
  <li>
  <span class="icon icon_mail"></span>
  <a href="mailto:fscph@zu.edu.ly">
  fscph@zu.edu.ly
  </a>
</li>
  
</ul>

`,
  en: `
<h4>
  <span class="icon far fa-calendar"></span> December  13, 2021 09:00 AM
</h4>
<div class="text"> Correspondence and communication
</div>
<ul class="info-list">
  <li>
    <span class="icon icon_profile"></span>Scientific Committee
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00218915293650">00218915293650</a>
  </li>
  
  <li>
    <span class="icon icon_profile"></span>Preparatory Committee
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00218910469116">00218910469116</a>
  </li>
  <li>
  <span class="icon icon_mail"></span>
  <a href="mailto:ficfs@zu.edu.ly">
  ficfs@zu.edu.ly
  </a>
</li>
  
</ul>
`,
  fr: `
<h4>
  <span class="icon far fa-calendar"></span> July 9, 2021 09:00 AM
</h4>
<div class="text"> Coordinateurs en anglais:
</div>
<ul class="info-list">
  <li>
    <span class="icon icon_profile"></span> Dr Muhammad: 
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00447986283872">00447986283872</a>
  </li>
  
  <li>
    <span class="icon icon_profile"></span> Dr. Munir
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00601137011603">00601137011603</a>
  </li>
  
</ul>
<div class="text">Coordinateur de la langue arabe:
 </div>
<ul class="info-list">
  <li>
    <span class="icon icon_profile"></span> Dr. Hassan
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00218913672429">00218913672429</a>
  </li>
  <li>
    <span class="icon icon_mail"></span>
    <a href="mailto:fscph@zu.edu.ly">
    fscph@zu.edu.ly
    </a>
  </li>
</ul>
<div class="text"> 
Coordinateur de la langue française:
</div>
<ul class="info-list">
  <li>
    <span class="icon icon_profile"></span> Dr.. Muhammad:
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00218912189196">00218912189196</a>
  </li>
  <li>
    <span class="icon icon_mail"></span>
    <a href="mailto:fscph@zu.edu.ly">
    fscph@zu.edu.ly
    </a>
  </li>
  <li>
  <span class="icon icon_profile"></span> Dr.. Fella Mohamd 
</li>
<li>
  <span class="icon icon_phone"></span>
  <a href="tel:0021698264476">0021698264476</a>
</li>
<li>
  <span class="icon icon_mail"></span>
  <a href="mailto:fscph@zu.edu.ly">
  fscph@zu.edu.ly
  </a>
</li>
</ul>
`,
};
export const about4 = {
  ar: "قواعد وشروط النشر",
  en: "Journal publishing rules and conditions",
  fr: `Règles et conditions de publication de revues`,
};

export const about5 = {
  ar: `<li>أن يسهم البحث في تعميق المعرفة ضمن محاور المؤتمر</li>
    <li>
      أن يكون البحث مستوفيا لشروط البحث العلمي، بما في ذلك ملخص البحث
      وعرض المشكلة ومراجعة الأدبيات ذات العلاقة والمنهجية المتبعة و
      تحليل البيانات وعرض النتائج والتوصيات .
    </li>
    <li>تقبل البحوث باللغة العربية واللغة الانجليزية .</li>
    <li>إتباع القواعد اللغوية السليمة في كتابة البحث.</li>
    <li>
      ألا يكون البحث جزءاً من رسالة الماجستير أو الدكتوراه التي أعدها
      الباحث, وألا يكون قد سبق نشره , أو تم إرساله للنشر في مجلة اخرى
      وألا يكون قد تمت المشاركة به في مؤتمر أو ندوة أو ورشة عمل.
    </li>
    <li>
      أن يكون البحث مكتوبا على ورق حجم A4 بحيث لا يقل حجم البحث عن 15
      صفحة و لا يزيد على 25 صفحة.
    </li>
    <li>
      تخضع البحوث المقدمة إلى المجلة للفحص والتقييم العلمي حسب الأصول
      المتبعة ، وتتحدد صلاحيتها للنشر بناء على رأي المحكمين بها.
    </li>
    <li>
      يلتزم الباحث بإجراء تعديلات المحكمين بالمؤتمر على بحثه وفق
      التقارير المرسلة إليه، وموافاة المجلة بنسخة معدلة خلال المدة المحددة وفقاً للتواريخ المعلنة.
    </li>
    <li>
      لا يجوز للباحث أن يطلب عدم نشر بحثه بعد إرساله للتحكيم إلا
      لأسباب تقتنع بها هيأة التحرير.
    </li>
    <li>
      
      يرفق بالبحث ملخص باللغتين العربية والانجليزية بما لا يزيد عن
      (200) كلمة، وتلي الملخص كلمات مفتاحية دالة لا تزيد عن (7 )
      للتعريف بنواحي البحث الرئيسة.
    </li>
    <li>
      يحق لهيأة التحرير - إذا رأت ضرورة لذلك - إجراء بعض التعديلات على
      البحث من دون المساس بمضمونه وبما يتوافق مع أسلوب المجلة في النشر
      .
    </li>
    <li>
      
      يكتب الباحث على ورقة منفصلة اسمه بالكامل ووظيفته الحالية ودرجته
      العلمية واسم المؤسسة التي يعمل بها وعنوانه مفصلاً وأرقام الهواتف
      والبريد الالكتروني، ويرفق مع البحث سيرة ذاتية مختصرة للتعريف
      بالباحث.
    </li>
    <li>
      يجب إثبات المصادر والمراجع في متن البحث بحيث يوضع اسم ولقب
      الباحث والسنة بين قوسين، وفي حالة الاقتباس المباشر يضاف رقم
      الصفحة.
    </li>
    <li>
      جميع المراجع المشار إليها في متن البحث يجب أن تدرج في قائمة
      المراجع في نهاية البحث قبل الملاحق – إن وجدت – وترتب ترتيباً
      هجائياً مبتدأه بالمراجع العربية أولاً ثم المراجع الأجنبية دون
      ترقيمها، وذلك وفقاً لما يلي
      <ul>
        <li>
          
          الكتب : اسم المؤلف أو المؤلفين ( السنة )، عنوان الكتاب ، اسم
          المحقق أو المترجم إن وجد ، (رقم الطبعة ؛ مكان النشر: اسم
          الناشر ) ، رقم الجزء . وفي حالة المراجع الأجنبية يكتب لقب
          المؤلف أو المؤلفين أولاً ثم بقية الاسم.
        </li>
        <li>
          
          البحث أو المقال المنشور في الدورية : اسم الكاتب أو الكتّاب (
          السنة) ، عنوان البحث أو المقال بين علامتي تنصيص ، اسم
          الدورية ، الجهة التي تصدر عنها الدورية، واسم البلد الصادرة
          عنها ، رقم المجلد ، رقم العدد . وفي حالة المراجع الأجنبية
          يكتب لقب الكاتب أو الكتّاب أولاً ثم بقية الاسم.
        </li>
        <li>
          الرسائل الجامعية : اسم مقدم الرسالة ( السنة) ،عنوان الرسالة
          أو الأطروحة، اسم الجامعة المانحة للرسالة.
        </li>
        <li>
          
          مصادر ومراجع الانترنت : اسم المؤلف أو المؤلفين (السنة)
          ،عنوان الكتاب أو المقال أو البحث ، اسم الموقع وعنوانه ،
          وتاريخ زيارة الموقع .
        </li>
      </ul>
    </li>
    <li>لا يحق للباحث المشاركة بأكثر من ورقة علمية واحدة في المؤتمر.</li>
    <li>
      يعتبر البحث المنشور ملكاً للمجلة وبالتالي لا يجوز إعادة نشر أي
      بحث منشور أو تم قبوله للنشر بأي شكل من الأشكال أو بأي لغة في أي
      جهة أخرى، إلا بإذن رسمي من رئيس هيأة التحرير.
    </li>
    <li>
      تكون النواحي الفنية في أعداد البحث على النحو التالي :
      <ul>
        <li>
          
          تكون الكتابة للبحوث العربية بالخط (Simplified Arabic ) ،
          وللبحوث باللغة الانجليزية بالخط ( Times New Roman ) مع ترك
          مسافة مفردة بين الأسطر.
        </li>
        <li>
          أن يكون حجم الخط على النحو التالي:
          <ol>
            <li> 18 (داكن) للعناوين الرئيسة .</li>
            <li>16 (داكن) للعناوين الفرعية.</li>
            <li>
              14 للمتن باللغة العربية و 12 للمتن باللغة الانجليزية.
            </li>
            <li>
              12 مائل للملخص باللغة العربية و 10 للملخص باللغة
              الانجليزية.
            </li>
            <li>10 للشرح أو التوضيح أسفل الصفحة.</li>
          </ol>
        </li>
        <li>
          أن تكون الهوامش على النحو التالي :
          <ol>
            <li>أعلى وأسفل 2.5 سم .</li>
            <li>أيمن 3 سم .</li>
            <li>أيسر 2.5 سم.</li>
          </ol>
        </li>
      </ul>
    </li>
   
    <li>
   	تسلم البحوث من خلال رابط التسجيل المخصص لاستقبال البحوث الموجود بالموقع الالكتروني.
    </li>`,
  en: `<p>Interventions and research papers that meet the requirements will be published in a special proceedings of the conference journal, according to the following conditions:</p>
  <ol>
  <li>That the research contributes to deepening knowledge within the conference axes.</li>
  <li>That the research meets the requirements of scientific research, including the summary of the research, presentation of the problem, review of the relevant literature and the methodology used, data analysis and presentation of findings and recommendations.</li>
  <li>The case study or field study for research should be conducted in one of the given sectors. Research that contains only a theoretical aspect is not accepted.</li>
  <li>Research is accepted in Arabic and English.</li>
  <li>Proper linguistic rules are followed in writing the research.</li>
  <li>The research should not be part of a Master's or Doctoral thesis, it should not have been previously published or sent for publication to another journal, and it has not been presented in another conference, seminar or workshop.</li>
  <li>The research paper is between 10 and 20 pages.</li>
  <li>The research should be written on A4 size paper.</li>
  <li>Research submitted to the journal is subject to scientific examination and evaluation according to the established rules, and its suitability for publication is determined by the Editorial Board.</li>
  <li>The researcher is obliged to accept the judgement of the editorial board on his/her research and, according to feedback provided, should present a modified copy within a period not exceeding 15 days.</li>
  <li>The researcher may not request that his/her research not be published after sending it for evaluation, except for reasons agreed by the editorial board.</li>
  <li>Attached to the research should be a summary in Arabic and English of no more than 200 words, followed by no more than 7 key words to define the main aspects of the research.</li>
  <li>The Editorial Board has the right - if it deems necessary - to make some adjustments to the research, without prejudice to its content, and in accordance with the journal's method of publication.</li>
  <li>The researcher should write on a separate paper his/her full name, current job, academic degree, the name of the institution he/she works for, a detailed address, phone number and e-mail, and attach to the research a brief CV.</li>
  <li>Research sources must be referenced in the body of the research, giving name of the researcher/s and the year in parentheses. In the case of a direct quotation, the page number should be added.</li>
  <li>All references referred to in the body of the research must be included in the list of references at the end of the research, before the annexes - if any - and arranged in alphabetical order, starting with the Arabic references first and then the foreign references, according to the following:<ol>
  <li>Books:The name of the author or authors (year), the title of the book, the name of the  translator, edition number, place of publication: name of the publisher. In the case of foreign references, the surname of the author or authors is written first and then their initials.
  
  </li>
  <li>Journal publication:The name of the author or writers (year), the title of the research or article in quotation marks, the name of the journal, the volume number, issue number and page numbers.</li>
  <li>Theses:The name of the author of the thesis (year), the title of the thesis or thesis, the name of the university providing the thesis.</li>
  <li>Internet resources and references:The name of the author or authors (year), the title of the book, article or research, the URL of the site, and the date the site was accessed.</li>
   </ol>
   </li>
  <li>No more than one research paper per researcher will be published in the proceedings.</li>
  <li>The published research is considered the copyright property of the journal and therefore it is not permissible to republish in any way or in any language in any other publication, except with the official permission of the Editor-in-Chief.</li>
  <li>The technical aspects for papers are as follows:
  <ol>
  <li> Times New Roman and Simplified Arabic- Single spaced.- Font size:18 bold for headlines-16 bold for subtitles.</li>
  <li>14 for the body in Arabic and 12 for the body in English.</li>
  <li>12 italic for abstract in Arabic and 10 for abstract in English.</li>
  <li>10 for explanation or clarification at the bottom of the page.</li>
  <li>The margins should be as follows:2.5 cm up and down, 3 cm right, 2.5 cm left.</li>
  </ol>
  </li>
  <li>All papers and correspondence to be submitted only through the designated address given in the conference announcements.</li>
  </ol>
  `,
};

export const Gools1 = {
  ar: `أهداف المؤتمر`,
  en: `The objectives of the conference`,
  fr: `Les objectifs de la conférence`,
};
export const AudienceTitle = {
  ar: `المستهدفون بالمشاركة`,
  en: `The objectives of the conference`,
  fr: `Les objectifs de la conférence`,
};

export const m = {
  ar: "محاور المؤتمر",
  en: "Conference Topics",
  fr: "Axes de la conférence",
};

export const m1 = {
  

  ar: ` المحور الأول | المحور الطبي`,
  en: "The First Topic | Analytical, organic, inorganic, physical, biochemistry, the applications of chemistry in pollution treatment, medicines, industry and technology ",
  fr: ` `,
};
export const m1_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  واقع وجودة الخدمات الصحية ودورها في إنجاح العلاج بالداخل.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  التحديات والصعوبات التي تواجه القطاع الصحي في ليبيا.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  استراتيجية استقطاب الخبرات والكفاءات الوطنية المهاجرة.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
مخرجات التعليم الطبي ومواءمتها لسوف العمل.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  جودة التشخيص وأهميتها في توطين العلاج. </li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  تجربة الأزمات ودورها في توطين العلاج بالداخل.
</li>
`,
};
export const m2 = {
  ar: " المحور الثاني | المحور الاقتصادي",
  en: "",
  fr: ` `,
};

export const m2_1 = {
  ar:`<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  الشراكة بين القطاع العام والخاص في توطين العلاج بالداخل.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  المرتبات والحوافز التشجيعية للعاملين في قطاع الصحة.</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  العلاج بالداخل والخارج وأثره على الاقتصاد الوطني. </li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  التأمين الصحي وأثره في توطين العلاج بالداخل.</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  أهمية السياسة الدوائية الوطنية ودورها في توطين العلاج.</li>
  <li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  العلاقة بين نفقات الصحة ومصادر التمويل في ليبيا.</li>
`,
};
export const m3 = {
  ar: `المحور الثالث | المحور الاجتماعي`,
  en: `The Third Topic | Physics and medical physics.`,
  fr: `` ,
};
export const m3_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  دور الكفاءات والمهارات البشرية في توطين العلاج بالداخل.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  رضا المواطن على الخدمات الطبية بالمؤسسات العلاجية الليبية.
  </li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  مساهمة منظمات ومؤسسات المجتمع المدني في ثقافة العلاج بالداخل.</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  دور الإعلام في تعزيز الثقة بين المواطن والخدمات الطبية بالداخل.</li>
`,
};

export const m4 = {
  ar: `   المحور الرابع | المحور القانوني والشرعي`,
  en: "The Fourth Topic |Computer Science and its Applications. ",
  fr: ``,
};
export const m4_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  القوانين والتشريعات ودورها في توطين العلاج بالداخل. 
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  الحماية القانونية للمرضى من الأخطاء الطبية في القانون الليبي. 
  </li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  حق المواطنين في العلاج وضمانه في القانوني الليبي.</li>
  <li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  العلاج بالداخل من وجهة نظر الشريعة وضوابطه الشرعية.</li>
`,
};

export const m5 = {
  ar: `المحور الخامس | اهمية الشراكة الفعالة بين القطاع العام والخاص في تحقيق توطين العلاج بالداخل.`,
   en: `The Fifth Topic | Ichthyology and Marine biology, Histology Molecular technique, Immunology & Hematology, Parasitology, Toxicology, Invertebrates and Entomology, Genetics, Ecology and covid-19.`,
 
  fr: ``,
  
};

export const m5_1 = {
  ar: ``,
};

 export const m6 = {
  ar: `المحور السادس | اهم التحديات  والصعوبات التي  تواجهه القطاع الصحي في ليبيا ومتطلبات تطويره.`,
  en: `The Sixth Topic | Mathematics: Algebra, Numerical analysis, Topology, Differential equations, Real analysis, Composite analysis..`,
  
  fr: ``,
 };

 export const m7 = {
  ar: ` المحور السابع | نظام الأجور والمرتبات والحوافز المالية للكوادر العاملة في قطاع الصحة ودوره في توطين العلاج بالداخل.`,
  en: ` The Seventh Topic | Earth Science: Geology and Geophysics`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };

 export const m8 = {
  ar: `المحور الثامن | استراتيجيات استقطاب الخبرات والكفاءات الوطنية المهاجرة خارج الوطن.`,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };
 export const m9 = {
  ar: `المحور التاسع | التحليل والمقارنة بين العلاج بالداخل والعلاج بالخارج وأثره على الاقتصاد الوطني. `,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };
 export const m10 = {
  ar: `المحور العاشر | التكامل بين مخرجات التعليم الطبي بفروعه وحاجة سوق الخدمات الصحية.`,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };
 export const m11 = {
  ar: `المحور الحادي عشر | دور الاعلام في توطين العلاج بالداخل.`,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };
 export const m12 = {
  ar: `المحور الثاني عشر | تحديد الاحتياجات التدريبية  والتقنية في المجال الصحي وانعكاساته على رفع كفاءة أداء العاملين في القطاع.`,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };
 export const m13 = {
  ar: `المحور الثالث عشر | الية نشر ثقافة العلاج بالداخل وتوطيد الفكرة بين شرائح المجتمع.`,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };
 export const m14 = {
  ar: `المحور الرابع عشر | دراسة جودة المختبرات الطبية في صحة التشخيص وبالتالي العلاج.`,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };
 export const m15 = {
  ar: `المحور الخامس عشر | التامين الصحي ودورة في توطين العلاج بالداخل.`,
   en: `The Eighth Topic | Plant physiology, Microbiology, Nutrition   and Pollution, Plant ecology, Anatomy and Taxonomy, Fungi.`,
//   fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
 };

// export const m6_1 = {
//   ar: `<li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>
//   الفرص والتهديدات للاستثمار في الطاقات الخضراء: (شمسية،
//   ومائية، وهوائية، وترابية ).
// </li>
// <li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>
//   سُبل تهيئة المناخ الاستثماري للقطاع الخاص في تبني
//   مشروعات الطاقات الخضراء.
// </li>
// <li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>
//   تجارب دولية ونماذج أعمال لاستثمار القطاع الخاص في
//   الطاقات الخضراء.
// </li>`,
//   en: `<li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>	Opportunities and threats to invest in green energies: (solar, water, air, and dusty).
// </li>
// <li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>	Ways to create an investment climate for the private sector in adopting green energy projects.
// </li>
// <li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>International experiences and business models for private sector investment in green energies.
// </li>`,
//   fr: `<li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>L’opportunités et les menaces d'investir dans les énergies vertes : (solaire, hydraulique, éolienne et terrestre).
// </li>
// <li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>Les moyens de préparer un climat d'investissement pour le secteur privé dans l'adoption de projets d'énergie verte.
// </li>
// <li>
//   <span
//     class="icon icon-plus fa flaticon-tick"
//     style={{ margin: "0 0.8rem" }}
//   ></span>
//   Les expériences internationales et les modèles commerciaux pour l'investissement du secteur privé dans les énergies vertes
// </li>`,
// };

export const Conferance = {
  ar: `المؤتمر العلمي الدولي الأول  لكلية الصحة العامة العجيلات`,
  en: `The 1st International Conference of the Faculties of Sciences`,
  fr: ``,
};
export const HowToRegester = {
  ar: `شرح لطريقة المشاركة في المؤتمر`,
  en: `Explain how to participate in the conference`,
  fr: `Expliquez comment participer à la conférence`,
};

export const comission = {
  ar: `لجان الإشراف على المؤتمر ...`,
  en: `Supervisory committees`,
  fr: `Comités de surveillance`,
};

export const allPapers = {
  ar: "إجمالي الملخصات المستلمة ",
  en: "Total abstracts received",
  fr: "Total des résumés reçus",
};
export const acceptedAbstructs = {
  ar: "عدد الملخصات المقبولة ",
  en: "Number of abstracts accepted",
  fr: "Nombre de résumés acceptés",
};
export const acceptedPapers = {
  ar: "عدد الورقات المقبولة ",
  en: "Number of accepted papers",
  fr: "Nombre de résumés acceptés",
};

export const rejectedPapers = {
  ar: "عدد الورقات المرفوضة ",
  en: "Number of rejected papers",
  fr: "Nombre de résumés rejetés",
};

export const s = {
  ar: `رعاة المؤتمر `,
  en: `Sponsors`,
  fr: `Les sponsors`,
};

export const Date1 = {
  ar: `الإعلان عن المؤتمر`,
  en: `Conference Announcement`,
  fr: `Receipt of research abstracts to  25-06-2021`,
};

export const Date11 = {
  ar:`الإعلان عن الملخصات المقبولة`,
  en: `The deadline for submitting research papers after making modifications`,
  fr: `Receipt of research papers to  01-08-2021`,
};
export const Date2 = {
  ar: `استقبال الملخصات `,
  en: `Deadline for accepting abstracts`,
  fr: `Announcement of accepted abstracts`,
};
export const Date21 = {
  ar: `نهاية قبول الورقات البحثية كاملة`,
  en: `Announcement of accepted papers`,
  fr: `Announcement of accepted papers`,
};
export const Date31 = {
  ar: `أخر موعد لاستقبال الملخصات  `,
  en: `Deadline for submitting complete research papers`,
  fr: `Accepter les offres des secteurs public et privé, les offres de produits et de projets`,
};

export const Date3 = {
  ar: `إعلان نتائج قبول الورقات البحثية`,
  en: `Opening of the conference`,
  fr: `Opening of the conference`,
};
export const Date4 = {
  ar: `قبول الورقات البحثية كاملة`,
  en: `Scientific conference sessions`,
  fr: `Scientific conference sessions and projects
  `,
};
export const Date5 = {
  ar: `أخر موعد لاستقبال الورقات البحثية`,
  en: `Follow-up of the scientific conference sessions`,
  fr: `Scientific sessions and projects`,
};
export const Date6_1 = {
  ar: `انعقاد المؤتمر `,
  en: `B2B/B2G Sessions Public and Private Sector: September 22, 2021
  `,
  fr: `Sessions B2B/B2G Secteur Public et Privé : 22 septembre 2021`,
};
export const Date6 = {
  ar: `الإعلان عن الورقات البحثية المقبولة`,
  en: `Conference Closing`,
  fr: `Mediterranean Youth Entrepreneur Forum &
  Investment opportunities in the agricultural, marine and transportation sectors.

  `,
};
// export const Date7 = {
//   ar: `رحلة سياحية`,
//   en: `Tourist trip  `,
//   fr: `Tourist trip  `,
// };
export const Date8 = {
  ar: `اختتام المؤتمر`,
  en: `Conclusion of the conference.`,
  fr: `Conclusion of the conference.`,
};

export const c1 = {
  ar: `المنسقون...`,
  en: `Coordinators`,
  fr: `coordinateurs`,
};

export const c2 = {
  ar: `
  
  <div class="col m6">
      <ol>
      <li>د. حسن الشيباني - ليبيا  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 00218913672429 </li>
    <li>
    د. مرصالي حورية - الجزائر <i
     class="material-icons teal-text"
     style={{ marginLeft: "15px" }}
   >
   phone
   </i>
   00213798481647
   </li>
   <li> د. منير الكبير - ماليزيا  <i
   class="material-icons teal-text"
   style={{ marginLeft: "15px" }}
 >
 phone
 </i> 00601137011603</li>
      </ol>
      <h6>لمزيدا من المعلومات الرجاء مراسلتنا على البريد الالكتروني التالي: fscph@zu.edu.ly</h6>
    </div>
`,
  en: `<div class="col m6">
   <ol>
    <li>Hassan Alshibany - Libya<i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 00218913672429 </li>
  <li>
  Morsali Houri - Algeria<i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i>
  00213798481647
  </li>
  <li>Munther Al-Kabeer - Malaysia  <i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
phone
</i> 00601137011603</li>
</ol>
<h5>If you have any questions,Please email us at  fscph@zu.edu.ly</h5>
</div>`,

  fr: `<div class="col m6">
<ol>
 <li>Hassan Alshibany - Libya<i
 class="material-icons teal-text"
 style={{ marginLeft: "15px" }}
>
phone
</i> 00218913672429 </li>
<li>
Morsali Houri - Algeria<i
 class="material-icons teal-text"
 style={{ marginLeft: "15px" }}
>
phone
</i>
00213798481647
</li>
<li>Munther Al-Kabeer - Malaysia  <i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
phone
</i> 00601137011603</li>
</ol>
<h5>Si vous avez des questions, veuillez nous envoyer un courriel à fscph@zu.edu.ly</h5>
</div>`,
};

export const D1 = {
  ar: `مواعيد مهمة`,
  en: `Important Dates`,
  fr: `Rendez-vous importants`,
};
export const D2 = {
  ar: `<ol> <li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  الإعلان عن المؤتمر:15-9-2020
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  قبول الورقات العلمية: من 01-10-2020 الى  15-12-2020 
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  موعد انعقاد المؤتمر: من 12-03-2021 الى 16-03-2021
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
   البرنامج التدريبي المصاحب: 15-03-2021
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  الرحلة السياحية: 16-03-2021
    </li>
  <li>
  <span class=" red-text"> ملاحظات:</span>   </br>
   في حال تعذر المشارك من الحضور لمقر انعقاد المؤتمر يمكنه عرض الورقة الكترونياً عبر الفضاء الالكتروني.</br>
  المشاركة والنشر مجاناً .</br>
 
</li>
</ol>
`,

  en: `<ol> <li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Conference announcement: 15-September-2020
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Paper Submission due: 01-October-2020 to 15-December-2020 
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Conference Date due: 12-Mar-2021 to 16-Mar-2021
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Training day: 15-Mar-2021
 </li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Tourist trip: 16-Mar-2021
  </li>
</ol>
<h5>Notes:</h5>   
<ol>
<li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
If the participant is unable to attend the conference venue, he can view the paper electronically via the electronic space.
</li>
<li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Participation and publication in the conference is free of charge.
</li><li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Free accommodation and accommodation at the conference venue in Tunis.
</li>
<li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
The conference are not responsible for transportation and travel costs.
</li>
</ol>
`,
  fr: `<ol> <li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Conference announcement: 15-September-2020
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Paper Submission due: 01-Octobre-2020 to 15-December-2020 
 </li>

<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Conference Date due: 12-mars-2021 to 16-mars-2021
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Training day: 15-mars-2021
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Tourist trip: 16-mars-2021
</li>
</ol>
<h5>Remarques:</h5>   
<ol>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
Si le participant n'est pas en mesure de se rendre sur le lieu de la conférence, il peut consulter le papier par voie électronique via l'espace électronique.
</li>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
La participation et la publication à la conférence sont gratuites.
</li><li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
Hébergement et hébergement gratuits sur le lieu de la conférence à Tunis.</li>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
La conférence n'est pas responsable des frais de transport et de déplacement.
</li>
</ol>
`,
};

export const footer = {
  ar: ` مركز المعلومات والتوثيق - جامعة الزاوية`,
  en: `Information & Documentation Center-Unversity of Zawia`,
  fr: ``,
};

export const Close = {
  ar: `اغلاق`,
  en: `Close`,
  fr: `Fermer`,
};

export const Bosses = [
  {
    image: "./img/team/team1.jpg",
    type: "none",
    name: "أ.سامي سعيد شلادي",
    capacity: "مشرف عام المؤتمر",
    capacity1: "وكيل عام مجلس التخطيط الوطني الليبي ",
  },
  {
    image: "./img/team/team2.jpg",
    name: "أ.د.عدالة العجال",
    type: "scientific",
    capacity: "رئيس اللجنة العلمية",
    committe: "العلمية",
  },
  {
    image: "./img/team/team3.jpg",
    name: "د.ياسين ابوسريويل",
    type: "preparatory",
    capacity: "رئيس اللجنة التحضيرية",
    committe: "التحضيرية",
  },
  {
    image: "./img/team/team4.jpg",
    type: "none",
    name: "د. الصديق خليفة الكيلاني ",
    capacity: "منسق للمؤتمر",
  },
];

export const CommiteeMembers = [
  {
    image: "",
    sex: "ذكر",
    name: "أ.حامد حسين بن كورة",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.احمد ابراهيم سليمان",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.حسن مولودالشيباني",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.أسماء البشير الشائبي",
    capacity: "رئيس لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    name: "أ.فطيمة الشيباني مسعود",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "حمزة موسي الدوكالي ",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "م.إلياس عبدالله بالحاج",
    capacity: "عضو اللجنة التقنية",
  },
  ///////////////////////////////////////////////*اللجنة الاعلامية*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "ذكر",
    name: "أحمد سعيد السنوسي",
    capacity: "رئيس الجنة الاعلامية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "المقداد سالم نصرات",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "محمد سالم ابوالقاسم",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "انثى",
    name: "عواطف البشير البشتي",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "انثى",
    name: "ميساء شيماء كريبازة",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "عبدالمجيد محمد ابوشعالة",
    capacity: "عضو اللجنة الاعلامية",
  },
];
export const OtherComitte = [
  ///////////////////////////////////////////////*اللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "د.عبدالسميع عامر معمر",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.حمزة الهادي كشلاف",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د. الزهرة الشيخ عبدالله",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.حسن على موتات",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "انثى",
    name: "أ.خولة بنت عبدالله التريكي",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د. محمد الزطريني",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "مصباح سالم الكانوني",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "طارق عبدالرزاق الطشاني",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "محمد خليفة المرزوقي",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "انثى",
    name: "أ.مروة محمد الزروق",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د. عبدالله عبدالرحيم",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  //////////////////////////////////////////////*لجنة التواصل*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.أسماء البشير الشائبي",
    capacity: "رئيس لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "احلام عبدالناصر بن طابون",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "وداد علي ابو شاقور",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.نور عبدالله شرلالة",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أماني ابراهيم الاربش",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.اسماء حسين  العقيلي",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.مارن خالد التائب",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "ذكر",
    type: "preparatory",
    name: "أ.محمد سالم بلوط",
    capacity: "عضو لجنة التواصل",
  },
  ///////////////////////////////////////////////*اللجنة العلمية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "أ.د.فلة محمد",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },

  {
    image: "",
    sex: "ذكر",
    name: "أ.د.باركة محمد الزين",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.باسم كاظم خلف",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.منير فرج الكبير",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.الجيلي محجوب أحمد",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.خلاصي عبد الإله",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.محمود أحمد فحيل البوم",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.جليل زين العابدين",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.نوري فلو  ",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د.فداء إبراهيم المصري",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د. بثينة الغلبزوري",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د.عياد ليلى",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.ميلود الرحالي",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د.مرصالي حورية",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.رجب عبد السلام العموري",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.وادي عز الدين",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.تراكة جمال",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.محمد الشيباني أبو شعفة",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.ياسر الكرماني ",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.عبد السلام محمد بلقاسم",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.عبد الله رمضان بنيني",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.سلاف السماوي",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
];

/////////////////////////////////////////////////////////الانجليزية/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////اللجنة التقنية EN////////////////////////////////////////////
export const CommiteeMembersEN = [
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamid Hussein bin Kora",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ahmed Ibrahim Suleiman",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Mouloud Al-Shaibani",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Fatima Al-Shaibani Masoud",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamza Moussa Al-Doukali ",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Elias Abdullah Belhaj",
    capacity: "Member of the Technical Committee",
  },
  ///////////////////////////////////////////////*ENاللجنة الاعلامية*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "ذكر",
    name: "Ahmed Saeed Al-Senussi",
    capacity: "Head of the Media Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Miqdad Salem Nusrat",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Salem Abu Al-Qasim",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Ewatif Al-bashir Al-bshty",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Maysa Shaimaa Kribaza",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Abdul Majeed Mohammed Abu Shaala",
    capacity: "Member of the Media Committee",
  },
];
export const OtherComitteEN = [
  //////////////////////////////////////////////*ENلجنة التواصل*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Al-Bashir Al-Shaibi",
    capacity: "Head of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Ahlam Nasser bin Tabon",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Wedad Ali Abu Shaqour",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Nour Abdullah Sharlala",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Amani Ibrahim Al-Arbash",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Hussein Al-Aqili",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Marne Khalid Taib",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mohammed Salem Ballout",
    capacity: "Member of the Communication Committee",
  },
  ///////////////////////////////////////////////*اللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdul Sami Amer Muammar",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hamza Al-Hadi Kashlaf",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Al-Zahra Sheikh Abdullah",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Ali Mutat",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Khawla bint Abdullah Al-Triki",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Muhammad Al-Zatarini",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mesbah Salem Al Kanouni",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Tariq Abdul Razzaq Al-Tashani",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Khalifa Al Marzouki",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Marwa Mohammed Al-Zarrouk",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdullah Abdul Rahim",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  ///////////////////////////////////////////////*ENاللجنة العلمية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr.Fella Muhammad",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Barkaa Muhammad Al-Zein",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Basem Kazem Khalaf",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mounir Farag Al Kabeer",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Al-Jelly Mahjoub Ahmed",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Khalasi Abdul Ilah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mahmoud Ahmed Fahail album",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Jalil Zine El Abidine",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Fida Ibrahim al-Masry",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Buthaina Al-Ghalbzouri",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Ayyad Layla",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Miloud El Rahali",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Marsali Hawria",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ragab Abdel Salam Al-Amouri",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Wadi Izz al-Din",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Trakha Jamal",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mohammed Al-Shaibani Abu Shaafa",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Yasser Al-Kirmani ",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdel Salam Mohamed Belkacem",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdullah Ramadan Benini",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Slaf Alsamawii",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
];

//////////////////////////////////////////////////////الفرنسية/////////////////////////////////////////////////
export const BossesFR = [
  {
    image: "./img/team/team1.jpg",
    type: "none",
    name: "Mr.Sami Saeed Shaladi",
    capacity: "superviseur général",
    capacity1: "Général adjoint du Conseil national de planification libyen ",
  },
  {
    image: "./img/team/team2.jpg",
    name: "Prof. Aadalh Al-Ajal ",
    type: "scientific",
    capacity: "Chef du comité scientifique",
  },
  {
    image: "./img/team/team3.jpg",
    name: "Dr.Yassin Abusriwel",
    type: "preparatory",
    capacity: "Président du Comité préparatoire",
  },
  {
    image: "./img/team/team4.jpg",
    type: "none",
    name: "Dr.Al-Siddiq Khalifa Al-Kilani ",
    capacity: "Coordinateur de conférence",
  },
];

/////////////////////////////////////////////////////////اللجنة التقنية FR/////////////////////////////////////
export const CommiteeMembersFR = [
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamid Hussein bin Kora",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ahmed Ibrahim Suleiman",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Mouloud Al-Shaibani",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Al Shaibi",
    capacity: "",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Fatima Al-Shaibani Masoud",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamza Moussa Al-Doukali ",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Elias Abdullah Belhaj",
    capacity: "Membre du comité technique",
  },
  ///////////////////////////////////////////////*FRاللجنة الاعلامية*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "ذكر",
    name: "Ahmed Saeed Al-Senussi",
    capacity: "Chef du comité des médias",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Miqdad Salem Nusrat",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Salem Abu Al-Qasim",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "انثى",
    name: "Ewatif Al-bashir Al-bshty",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "انثى",
    name: "Maysa Shaimaa Kribaza",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Abdul Majeed Mohammed Abu Shaala",
    capacity: "Membre du comité des médias",
  },
];
export const OtherComitteFR = [
  //////////////////////////////////////////////*FRلجنة التواصل*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Al-Bashir Al-Shaibi",
    capacity: "Chef du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Ahlam Nasser bin Tabon",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Wedad Ali Abu Shaqour",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Nour Abdullah Sharlala",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Amani Ibrahim Al-Arbash",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Hussein Al-Aqili",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Marne Khalid Taib",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mohammed Salem Ballout",
    capacity: "Membre du comité de communication",
  },
  ///////////////////////////////////////////////*FRاللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdul Sami Amer Muammar",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hamza Al-Hadi Kashlaf",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Al-Zahra Sheikh Abdullah",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Ali Mutat",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Khawla bint Abdullah Al-Triki",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Muhammad Al-Zatarini",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mesbah Salem Al Kanouni",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Tariq Abdul Razzaq Al-Tashani",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Khalifa Al Marzouki",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "انثى",
    name: "Marwa Mohammed Al-Zarrouk",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdullah Abdul Rahim",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  ///////////////////////////////////////////////*FRاللجنة العلمية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr.Fella Muhammad",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Barkaa Muhammad Al-Zein",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Basem Kazem Khalaf",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mounir Farag Al Kabeer",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Al-Jelly Mahjoub Ahmed",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Khalasi Abdul Ilah",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mahmoud Ahmed Fahail album",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Jalil Zine El Abidine",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Fida Ibrahim al-Masry",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Buthaina Al-Ghalbzouri",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Ayyad Layla",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Miloud El Rahali",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Marsali Hawria",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ragab Abdel Salam Al-Amouri",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Wadi Izz al-Din",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Trakha Jamal",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mohammed Al-Shaibani Abu Shaafa",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Yasser Al-Kirmani ",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdel Salam Mohamed Belkacem",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdullah Ramadan Benini",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Slaf Alsamawii",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
];

export const vedname = {
  en: "Une vidéo explicative en français expliquant les objectifs et les thèmes de la conférence",
  ar: "فيديو  يوضح أهداف ومحاور المؤتمر. ",
  fr: "Une vidéo explicative en français expliquant les objectifs et les thèmes de la conférence",
};

export const btnTitle = {
  en: "How to Register",
  ar: "كيفية التسجيل",
  fr: "Comment s'inscrire",
};
export const btnTitleG = {
  en: "Thèmes de la conférence",
  ar: "شرح محاور المؤتمر",
  fr: "Thèmes de la conférence",
};

export const CountDown = {
  ar: "العد التنازلي لقبول الاوراق البحثية",
  en: "Countdown to the acceptance of the research papers",
  fr: "Compte à rebours pour l'acceptation des articles de recherche",
};
