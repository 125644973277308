import React, { useEffect, useState } from "react";
import "./timer.style.css";

function App({ lang, time, bgc }) {
  const calculateTimeLeft = () => {
    const difference = +new Date(time) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <>
        {interval === "days" ? (
          <div class="counter-column">
            <span class="count">{timeLeft[interval]}</span>
            {lang === "ar" ? "يوم" : "day"}
          </div>
        ) : null}
        {interval === "hours" ? (
          <div class="counter-column">
            <span class="count">{timeLeft[interval]}</span>
            {lang === "ar" ? "ساعات" : interval}
          </div>
        ) : null}
        {interval === "minutes" ? (
          <div class="counter-column">
            <span class="count">{timeLeft[interval]}</span>
            {lang === "ar" ? "دقائق" : interval}
          </div>
        ) : null}
        {interval === "seconds" ? (
          <div class="counter-column">
            <span class="count">{timeLeft[interval]}</span>
            {lang === "ar" ? "ثواني" : interval}
          </div>
        ) : null}
      </>
    );
  });

  return (
    <div>
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span>انعقد المؤتمر بتاريخ : 22-02-2022</span>
      )}
    </div>
  );
}

export default App;
