import React, { useState } from "react";
import { Link } from "react-scroll";
import { ChangeLang } from "../actions/research";
import { connect, useSelector } from "react-redux";
import {
  navlink2,
  navlink2_1,
  navlink3,
  navlink4,
  navlink4_4,
  navlink4_5,
  navlink6,
  navlink7,
  navlink5,
  Gools1,
  AudienceTitle,
  head4,
  m,
} from "./sections";

const Navbars = ({ ChangeLang }) => {
  const [Toggle, setToggle] = useState("none");
  const onToggle = () => {
    Toggle === "none" ? setToggle("block") : setToggle("none");
  };
  const lang = useSelector((state) => state.research.lang);
  return (
    <header className="main-header header-style-two">
      <div className="main-box">
        <div className="auto-container ">
          <div
            className="logo-box"
            style={{
              float: lang === "ar" ? "right" : "left",
              marginRight: "2rem",
            }}
          >
            <div className="logo">
              <a href="index.html">
                <img
                  src={lang === "ar" ? "images/logo-2.png" : "images/logoE.png"}
                  width="190"
                  alt=""
                  title=""
                />
              </a>
            </div>
          </div>

          <div className="nav-outer">
            <div
              className="mobile-nav-toggler"
              onClick={onToggle}
              style={{ zIndex: "999" }}>
              <span className="icon flaticon-menu"></span>
            </div>
            {/* {lang !== "ar" && (
              <div
                className="outer-box"
                style={{
                  float: "right",
                  marginLeft: "2rem",
                }}
              >
                <div className="btn-box">
                  
                  <a
                    href="#"
                    className="theme-btn btn-style-one"
                    onClick={() => ChangeLang("ar")}
                  >
                    <span className="btn-title">Ar</span>
                  </a>
                  
                  <a
                    href="#"
                    className="theme-btn btn-style-one"
                    onClick={() => ChangeLang("en")}
                  >
                    <span className="btn-title">En</span>
                  </a>
                  
                  <a
                    href="#"
                    className="theme-btn btn-style-one"
                    onClick={() => ChangeLang("fr")}
                  >
                    <span class="btn-title">Fr</span>
                  </a>
                </div>
              </div>
            )} */}
            <nav
              collapseOnSelect
              expand="lg"
              sticky="top"
              className="main-menu navbar-expand-md navbar-light"
            >
              <div className="navbar-header">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon flaticon-menu-button"></span>
                </button>
              </div>
              <div
                className="collapse navbar-collapse clearfix"
                id="navbarSupportedContent"
              >
                <ul
                  className="navigation clearfix"
                  style={{
                    direction: lang !== "ar" && "ltr !important",
                    textAlign: lang !== "ar" && "left",
                  }}
                >
                  <li
                    className="dropdown"
                    style={{ float: lang === "ar" && "right" }}
                  >
                    <a href="!#">{navlink4[lang]}</a>
                    <ul
                      style={{
                        right: lang === "ar" && "0",
                      }}
                    >
                      <li>
                        {" "}
                        <Link
                          to="about"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {head4[lang]}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="goals"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {Gools1[lang]}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="hubs"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {m[lang]}
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="Audience"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {AudienceTitle[lang]}
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="video"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {navlink4_4[lang]}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="statist"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {navlink4_5[lang]}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="plan"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {navlink7[lang]}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <Link
                      to="commitee"
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                    >
                      {navlink6[lang]}
                    </Link>
                  </li> */}

                  <li style={{ float: lang === "ar" && "right" }}>
                    <Link
                      to="orgenazer"
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                    >
                      {/* {navlink5[lang]} & {navlink2[lang]} */}
                      {navlink2[lang]}
                    </Link>
                  </li>
                  <li style={{ float: lang === "ar" && "right" }}>
                    <Link
                      to="sponsors"
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                    >
                      {/* {navlink5[lang]} & {navlink2[lang]} */}
                      {navlink2_1[lang]}
                    </Link>
                  </li>
                  {/* <li style={{ float: lang === "ar" && "right" }}>
                    <Link
                      to="speakers"
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                    >
                      {navlink3[lang]}
                    </Link>
                  </li> */}
                </ul>
                {lang === "ar" && (
              <div
                className="outer-box"
                style={{
                  float: "right",
                  marginLeft: "2rem",
                }}
              >
                {/* <div className="btn-box">
                  
                  <a
                    href="#"
                    className="theme-btn btn-style-one"
                    onClick={() => ChangeLang("ar")}
                  >
                    <span className="btn-title">Ar</span>
                  </a>
                  
                  <a
                    href="#"
                    className="theme-btn btn-style-one"
                    onClick={() => ChangeLang("en")}
                  >
                    <span className="btn-title">En</span>
                  </a>
                  
                  <a
                    href="#"
                    className="theme-btn btn-style-one"
                    onClick={() => ChangeLang("fr")}
                  >
                    <span class="btn-title">Fr</span>
                  </a>
                </div> */}
              </div>
            )}
              </div>
            </nav>
            {lang === "ar" && (
              <div
                className="outer-box"
                style={{ float: lang === "ar" ? "left" : "right" }}
              >
                
              </div>
            )}
          </div>
        </div>
      </div>

      <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn">
          <span class="icon flaticon-cancel-1"></span>
        </div>

        <nav class="menu-box">
          <div class="nav-logo">
            <a href="index.html">
              <img src="images/logo-2.png" alt="" title="" />.
            </a>
          </div>

          <ul class="navigation clearfix"></ul>
        </nav>
      </div>
      {/* mobile NavBar */}
      <ul
        style={{
          padding: "1rem 3rem",
          paddingTop: "6rem",
          lineHeight: "2.8rem",
          fontSize: "1.2rem",
          textAlign: lang === "ar" ? "right" : "left",
          display: Toggle,
          transition: "all 0.5s ease-in-out",
        }}
      >
        <li className="dropdown">
          {/* eslint-disable-next-line  */}
          <a>{navlink4[lang]}</a>
          <ul style={{ padding: "0 2rem" }}>
            <li>
              {" "}
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                {head4[lang]}
              </Link>
            </li>
            <li>
              <Link
                to="goals"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                {Gools1[lang]}
              </Link>
            </li>

            <li>
              <Link
                to="hubs"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                {m[lang]}
              </Link>
            </li>
            {/* <li>
                        <Link
                          to="Audience"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          {AudienceTitle[lang]}
                        </Link>
                      </li> */}
            <li>
              <Link
                to="statist"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                {navlink4_5[lang]}
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="video" spy={true} smooth={true} offset={50} duration={500}>
            {navlink4_4[lang]}
          </Link>
        </li>
        <li>
          <Link
            to="commitee"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            {navlink6[lang]}
          </Link>
        </li>
        <li>
          <Link
            to="orgenazer"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            {navlink2[lang]}
          </Link>
        </li>
        <li>
          <Link
            to="sponsors"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            {navlink2_1[lang]}
          </Link>
        </li>
        {/* <li>
          <Link
            to="speakers"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            {navlink3[lang]}
          </Link>
        </li> */}
        <li>
          <Link to="plan" spy={true} smooth={true} offset={50} duration={500}>
            {navlink7[lang]}
          </Link>
        </li>

        <div className="btn-box">
          
          {/* <a
            href="#"
            className="theme-btn btn-style-one"
            onClick={() => ChangeLang("ar")}
            style={{ padding: "0.5rem", margin: "0.4rem" }}
          >
            <span className="btn-title">Ar</span>
          </a>
          
          <a
            href="#"
            className="theme-btn btn-style-one"
            onClick={() => ChangeLang("en")}
            style={{ padding: "0.5rem", margin: "0.4rem" }}
          >
            <span className="btn-title">En</span>
          </a> */}
          
          {/* <a
            href="#"
            className="theme-btn btn-style-one"
            onClick={() => ChangeLang("fr")}
            style={{ padding: "0.5rem", margin: "0.4rem" }}
          >
            <span class="btn-title">Fr</span>
          </a> */}
        </div>
      </ul>
    </header>
  );
};

export default connect(null, { ChangeLang })(Navbars);
