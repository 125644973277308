import React, { Fragment, useState } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";

import { connect } from "react-redux";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SearchMadal from "./SearchMadol";
import { setAlert } from "../actions/alert";
import { addAbstruct } from "../actions/research";
import FormInput from "./form-input/form-input.component";
import { m1, m2, m3, m4, m5, m6,m7,m8 } from "./sections";
import Alert from "./Alert";

const Regester = ({ lang, setAlert, addAbstruct }) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [msg, setMsg] = useState("");
  // for regester
  const [Research, setResearch] = useState({
    researchName: "",
    abstruct: "",
    theHub: "",
    author: "",
    email: "",
    phone: "",
    country: "",
    Organization: "",
    Qualification: "",
    TypeOfParticipation: "",
    Degree: "",
    author1: "",
    email1: "",
    author2: "",
    email2: "",
    birthDate: "",
    birthDate2: "",
    birthDate3: "",
  });

  const theHubSource = [
    m1[lang],
    m2[lang],
    m3[lang],
    m4[lang],
    m5[lang],
    m6[lang],
    m7[lang],
    m8[lang],
  ];

  const onChange = (e) => {
    setResearch({ ...Research, [e.target.name]: e.target.value });
  };

  const onChangeSelect = (selected) => {
    setResearch({ ...Research, theHub: selected.value });
  };

  let options = [];
  theHubSource.map((hub, index) =>
    options.push({
      value: index + 1,
      label: hub,
    })
  );

  const {
    researchName,
    abstruct,
    theHub,
    author,
    email,
    phone,
    country,
    Organization,
    Qualification,
    TypeOfParticipation,
    Degree,
    author1,
    email1,
    author2,
    email2,
    birthDate,
    birthDate2,
    birthDate3,
  } = Research;

  const onSubmit = () => {
    if (
      researchName === "" ||
      abstruct === "" ||
      // theHub === "" ||
      author === "" ||
      email === "" ||
      phone === "" ||
      country === "" ||
      Organization === "" ||
      TypeOfParticipation === "" ||
      Qualification === "" ||
      Degree === ""
    ) {
      setAlert("كل الحقول مطلوبة عدا الباحث التاني و الثالث", "danger");
    } else {
      addAbstruct(
        researchName,
        abstruct,
        theHub,
        author,
        email,
        phone,
        country,
        Organization,
        Qualification,
        TypeOfParticipation,
        Degree,
        author1,
        email1,
        author2,
        email2,
        birthDate,
        birthDate2,
        birthDate3
      );
      setAlert(
        `تم ارسال بيانات التسجيل علي الايميل ${email} في حالة عدم الرد في مدة اقصاها 01-09-2021 ايام يمكنك الاتصال بالمنسقين`,
        "success"
      );
      setResearch({
        researchName: "",
        abstruct: "",
        theHub: "",
        author: "",
        email: "",
        phone: "",
        country: "",
        Organization: "",
        Qualification: "",
        TypeOfParticipation: "",
        Degree: "",
        author1: "",
        email1: "",
        author2: "",
        email2: "",
        birthDate: "",
        birthDate2: "",
        birthDate3: "",
      });
      setShow(false);
    }
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a className="theme-btn btn-style-one" onClick={handleShow}>
        <span className="btn-title">
          {lang === "ar" ? "أعضاء اللجنة" : "Upload Scientific Participation"}{" "}
        </span>{" "}
      </a>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> المشاركة في المؤتمر </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ direction: "rtl" }}>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            {/* <Tab eventKey="home" title="التسجيل في المؤتمر">
              <>
                <div className="container">
                  {lang === "ar" ? (
                    <Fragment>
                      <ol className="red-text mt-4">
                        <li>كل الحقول مطلوبة عدا الباحث الثاني و الثالث </li>
                        <li>
                          يجب ادخال الملخص باللغتين العربية و الانجليزية في حقل
                          الملخص
                        </li>
                        <li>
                          يرجى تحميل الورقة البحثية بإحدى الصيغتين (doc او docx){" "}
                        </li>
                        <li>
                        في حالة عدم استقبالك لاي رسالة على بريدك الالكتروني الرجاء التواصل مع اللجنة على البريد الالكتروني التالي:ficfs@zu.edu.ly
                        </li>
                      </ol>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <br />
                      <ol
                        className="red-text left-align"
                        style={{ direction: "rtl" }}
                      >
                        <li>
                          All the fields are required except the fields released
                          to the second and third author{" "}
                        </li>
                        <li>
                          {" "}
                          The abstract must be submitted both in Arabic and
                          English using the abstract field.{" "}
                        </li>
                        <li>
                          {" "}
                          Please upload your CV in one of these format (doc or
                          pdf){" "}
                        </li>
                        <li>
                          If you do not receive a replay to your e-mail, please
                          do not hesitate to contact us with via the E-mail:
                          ficfs@zu.edu.ly
                        </li>
                      </ol>
                    </Fragment>
                  )}
                  <FormInput
                    type="text"
                    name="researchName"
                    value={researchName}
                    onChange={onChange}
                    required
                    label=" Title of Paper / عنوان الورقة العلمية"
                  />
                  <Select
                    options={options}
                    name="department"
                    placeholder={<div>Choose the Topic / اختر المحور </div>}
                    onChange={onChangeSelect}
                    required
                  />
                  <br />
                  <select
                    name="TypeOfParticipation"
                    id="TypeOfParticipation"
                    onChange={onChange}
                  >
                    <option value="">اختر نوع امشاركة</option>
                    <option value="offline">الحضور الفعلي </option>
                    <option value="online">المشاركة عن بعد</option>
                  </select>
                  <br />
                  <label htmlFor="firstName" className="active">
                  Abstract / ملخص الورقة و مكونات البحث
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={abstruct}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setResearch({ ...Research, abstruct: data });
                    }}
                  />

                  <FormInput
                    type="text"
                    name="author"
                    value={author}
                    onChange={onChange}
                    required
                    label=" Name of Author 1 / اسم المشارك الذى سيعرض الورقة
              "
                  />
                  <FormInput
                    type="date"
                    name="birthDate"
                    value={birthDate}
                    onChange={onChange}
                    required
                    label=" Date of Birth / تاريخ الميلاد
              "
                  />
                  <FormInput
                    type="text"
                    name="email"
                    value={email}
                    onChange={onChange}
                    required
                    label=" Email of Author 1 / البريد الالكترونى"
                  />
                  <FormInput
                    type="number"
                    name="phone"
                    value={phone}
                    onChange={onChange}
                    required
                    label=" Phone of Author 1 / رقم الهاتف"
                  />
                  <FormInput
                    type="text"
                    name="country"
                    value={country}
                    onChange={onChange}
                    required
                    label="Country / الدولة *"
                  />
                  <FormInput
                    type="text"
                    name="Organization"
                    value={Organization}
                    onChange={onChange}
                    required
                    label=" Organization of Authors / الجهة التابع لها المشارك ( جامعة- كلية /
                مركز بحثى / الخ) *"
                  />
                  <FormInput
                    type="text"
                    name="Qualification"
                    value={Qualification}
                    onChange={onChange}
                    required
                    label="Qualification / لمؤهل العلمى / التخصص"
                  />
                  <FormInput
                    type="text"
                    name="Degree"
                    value={Degree}
                    onChange={onChange}
                    
                    label="Degree(optional) / الدرجة العلمية (إختياري)"
                  />
                  <FormInput
                    type="text"
                    name="author1"
                    value={author1}
                    onChange={onChange}
                    label="Name of Author 2 / اسم الباحث التاني ان وجد"
                  />
                  <FormInput
                    type="date"
                    name="birthDate2"
                    value={birthDate2}
                    onChange={onChange}
                    required
                    label=" Date of Birth / تاريخ الميلاد
              "
                  />
                  <FormInput
                    type="text"
                    name="email1"
                    value={email1}
                    onChange={onChange}
                    label="Email of Author 2 / البريد الالكترونى للباحث التاني"
                  />
                  <FormInput
                    type="text"
                    name="author2"
                    value={author2}
                    onChange={onChange}
                    label=" Name of Auther 3 / اسم الباحث الثالث ان وجد"
                  />
                  <FormInput
                    type="date"
                    name="birthDate3"
                    value={birthDate3}
                    onChange={onChange}
                    required
                    label=" Date of Birth / تاريخ الميلاد
              "
                  />
                  <FormInput
                    type="text"
                    name="email2"
                    value={email2}
                    onChange={onChange}
                    label="Email of Author 3 البريد الالكترونى للباحث الثالث"
                  />
                </div>
                <Alert />
                <Modal.Footer>
                  <button
                    className="theme-btn btn-style-one"
                    onClick={onSubmit}
                  >
                    <span className="btn-title">ارسال </span>{" "}
                  </button>
                  <button
                    className="theme-btn btn-style-three"
                    onClick={handleClose}
                  >
                    <span className="btn-title">اغلاق </span>
                  </button>
                </Modal.Footer>
              </>
            </Tab> */}
            <Tab eventKey="profile" title="لمراجعة حالة الملخص او تحميل الورقة في حالة القبول انقر هنا">
              <SearchMadal />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  setAlert,
  addAbstruct,
})(Regester);
