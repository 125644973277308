import React from "react";

const Footer = () => {
  return (
    <footer class="main-footer style-three">
      <div class="anim-icons full-width">
        <span class="icon icon-circle-blue wow fadeIn"></span>
        <span class="icon icon-dots wow fadeInleft"></span>
        <span class="icon icon-circle-1 wow zoomIn"></span>
      </div>

      <div class="auto-container">
        <div class="footer-content">
          <div class="footer-logo">
            <a href="#">
              <img src="images/logo-2.png" width="300" alt="" />
            </a>
          </div>

          <div class="copyright-text">
            © كل الحقوق محفوظة <a href="index.html"> لمركز المعلومات والتوثيق بجامعة الزاوية </a>
          </div>
          <ul class="social-icon-one">
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100075125617963"
              >
                <span class="fab fa-facebook-f"></span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <span class="fab fa-twitter"></span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="fab fa-pinterest"></span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="fab fa-linkedin-in"></span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
