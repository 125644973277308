import React, { Fragment, useState } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";

import { connect } from "react-redux";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SearchMadal from "./SearchMadol";
import { setAlert } from "../actions/alert";
import { addAbstruct } from "../actions/research";
import FormInput from "./form-input/form-input.component";
import { m1, m2, m3, m4, m5, m6,m7,m8 } from "./sections";
import Alert from "./Alert";

const Tech = ({ lang, setAlert, addAbstruct }) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [msg, setMsg] = useState("");
  // for regester
  const [Research, setResearch] = useState({
    researchName: "",
    abstruct: "",
    theHub: "",
    author: "",
    email: "",
    phone: "",
    country: "",
    Organization: "",
    Qualification: "",
    TypeOfParticipation: "",
    Degree: "",
    author1: "",
    email1: "",
    author2: "",
    email2: "",
    birthDate: "",
    birthDate2: "",
    birthDate3: "",
  });

  const theHubSource = [
    m1[lang],
    m2[lang],
    m3[lang],
    m4[lang],
    m5[lang],
    m6[lang],
    m7[lang],
    m8[lang],
  ];

  const onChange = (e) => {
    setResearch({ ...Research, [e.target.name]: e.target.value });
  };

  const onChangeSelect = (selected) => {
    setResearch({ ...Research, theHub: selected.value });
  };

  let options = [];
  theHubSource.map((hub, index) =>
    options.push({
      value: index + 1,
      label: hub,
    })
  );

  const {
    researchName,
    abstruct,
    theHub,
    author,
    email,
    phone,
    country,
    Organization,
    Qualification,
    TypeOfParticipation,
    Degree,
    author1,
    email1,
    author2,
    email2,
    birthDate,
    birthDate2,
    birthDate3,
  } = Research;

  const onSubmit = () => {
    if (
      researchName === "" ||
      abstruct === "" ||
      // theHub === "" ||
      author === "" ||
      email === "" ||
      phone === "" ||
      country === "" ||
      Organization === "" ||
      TypeOfParticipation === "" ||
      Qualification === "" ||
      Degree === ""
    ) {
      setAlert("كل الحقول مطلوبة عدا الباحث التاني و الثالث", "danger");
    } else {
      addAbstruct(
        researchName,
        abstruct,
        theHub,
        author,
        email,
        phone,
        country,
        Organization,
        Qualification,
        TypeOfParticipation,
        Degree,
        author1,
        email1,
        author2,
        email2,
        birthDate,
        birthDate2,
        birthDate3
      );
      setAlert(
        `تم ارسال بيانات التسجيل علي الايميل ${email} في حالة عدم الرد في مدة اقصاها 01-09-2021 ايام يمكنك الاتصال بالمنسقين`,
        "success"
      );
      setResearch({
        researchName: "",
        abstruct: "",
        theHub: "",
        author: "",
        email: "",
        phone: "",
        country: "",
        Organization: "",
        Qualification: "",
        TypeOfParticipation: "",
        Degree: "",
        author1: "",
        email1: "",
        author2: "",
        email2: "",
        birthDate: "",
        birthDate2: "",
        birthDate3: "",
      });
      setShow(false);
    }
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <a className="theme-btn btn-style-three" onClick={handleShow}>
        <span className="btn-title">
          {lang === "ar" ? "أعضاء اللجنة" : "Upload Scientific Participation"}{" "}
        </span>{" "}
      </a>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header style={{ direction: "rtl" }}>
          <Modal.Title> أعضاء اللجنة </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ direction: "rtl" }}>

          <ul>
            <li> د. محمد الشيباني ابوشعفة</li>
            <li>أ. احمد ابراهيم سليمان</li>
           </ul>
          <Modal.Footer>
            
            <button className="theme-btn btn-style-one" onClick={handleClose}>
              <span className="btn-title">اغلاق </span>
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  setAlert,
  addAbstruct,
})(Tech);
