import React from "react";
import { useSelector } from "react-redux";
import { s, navlink2_1 } from "../common/sections";
const Sponsor = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section
      id="sponsors"
      className="clients-section"
      style={{
        direction: lang !== "ar" && "ltr",
        textAlign: lang !== "ar" && "left",
      }}
    >
      <div className="anim-icons">
        <span className="icon icon-dots-3 wow zoomIn"></span>
        <span className="icon icon-circle-blue wow zoomIn"></span>
      </div>
      <div className="auto-container">
        <div className="sec-title">
          <span className="newtitle text-center"> {navlink2_1[lang]}</span>
          {/* <h2>& {navlink2[lang]}</h2> */}
        </div>

        <div className="sponsors-outer">
          {/* <h3>{navlink2[lang]}</h3> */}
          <div className="row">
            {/* <!-- Client Block --> */}
            {/* <div className="client-block col-lg-6 col-md-6 col-sm-12">
              <figure className="image-box">
                <a href="http://zu.edu.ly/faculty/5ddc41545a75af704ad9b502">
                  <img
                    src="images/inter.png"
                    style={{ minHeight: "130px" }}
                    alt=""
                  />
                </a>
              </figure>
            </div> */}
            <div className="client-block col-lg-12 col-md-12 col-sm-12">
              <figure className="image-box">
                <a href="https://www.health.gov.ly/">
                  <img
                    src="images/saha.png"
                    style={{ minHeight: "130px" }}
                    alt=""
                  />
                </a>
              </figure>
            </div>
           
            {/* <!-- Client Block --> */}
            {/* <div className="client-block col-lg-6 col-md-6 col-sm-12">
              <figure className="image-box">
                <a href="https://www.unikl.edu.my/">
                  <img
                    src="images/uni.jpeg"
                    alt=""
                    style={{ height: "130px", padding: "30px 0" }}
                  />
                </a>
              </figure>
            </div> */}

            {/* <!-- Client Block --> */}
          </div>
        </div>

        {/* <div className="sponsors-outer">
          <h3>{s[lang]}</h3>

          <div className="row">
            <!-- Client Block -->
            <div className="client-block col-lg-3 col-md-6 col-sm-12">
              <figure className="image-box">
                <a href="#">
                  <img src="images/gov.png" alt="" />
                </a>
              </figure>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Sponsor;
