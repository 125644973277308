import React, { Fragment, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../actions/alert";
import FormInput from "./form-input/form-input.component";
import UploadPaperModal from "./uploadPaperModal";
import Alert from "./Alert";

const ContactFilter = () => {
  const [research, setResearch] = useState("");
  const [ref, setref] = useState("");
  const { researchs, lang } = useSelector((state) => state.research);
  const dispatch = useDispatch();
  const clearReserch = () => {
    setResearch("");
    dispatch(setAlert("تم تحميل الورقة بنجاح ", "success"));
  };
  const onChange = async (e) => {
    setref(e.target.value);
    console.log(e.target.value);
    if (e.target.value.length === 24) {
      let p = await researchs.find(
        (research) => research._id === e.target.value
      );

      if (!p) {
        dispatch(
          setAlert(
            "لا يوجد ورقة بهدا الرقم تآكد من رقم الورقة من بريدك الالكتروني",
            "danger"
          )
        );
      } else if (p && (!p.abstructStatus || p.abstructStatus === "")) {
        dispatch(
          setAlert(
            `تم استلام ملخص  الورقة بعنوان : '  ${p.researchName}  ' و يتم مراجعتها من قبل اللجنة العلمية  
       `,
            "success"
          )
        );
      } else if (p && p.abstructStatus === "true") {
        if (p.paper && p.paper.length > 0) {
          dispatch(
            setAlert(
              `تم استلام   الورقة كاملة بعنوان : '  ${p.researchName}  ' و يتم مراجعتها من قبل اللجنة العلمية  
         `,
              "success"
            )
          );
        } else {
          dispatch(
            setAlert("تم قبول الملخص ارجو   تحميل الورقة   ", "success")
          );
          setResearch(p);
        }
      } else if (p && p.abstructStatus === "false") {
        dispatch(
          setAlert(
            `آ.
          ${p.author} ,
          للآسف !
          لم يتم قبول ورقتك يمكنك الاطلاع علي بريدك للاطلاع علي مزيد من المعلومات`,
            "danger"
          )
        );
      }

      setref("");
    }
  };

  return (
    <Fragment>
      <div class="row">
        <div class="col s12">
          <h5 className="text-center m-4 ">
            {lang === "ar"
              ? "للمراجعة عن حالة الملخص و تحميل الورقة في حالة القبول ادخل رمز الملخص "
              : "To Review The Status Of Paper"}
          </h5>
          <div class="text-center pr-5 pl-5">
            <FormInput
              type="text"
              name="researchName"
              value={ref}
              onChange={onChange}
              required
              label={
                lang === "ar"
                  ? "ادخل رمز الورقة عدد رموز الورقة 24 رقم ..."
                  : "Please Enetr the ID of the Paper"
              }
              autoFocus
              id="autocomplete-input"
            />
            <Alert />
          </div>

          {research.abstructStatus === "true" && (
            <UploadPaperModal research={research} clearReserch={clearReserch} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ContactFilter;
